<template>
  <div class="overflow-hidden">
    <Header />
    <section class="bg-light d-none d-lg-block">
      <div class="container py-4">
        <div class="bg-light">
          <div class="d-flex justify-content-start">
            <div
              class="
                col-5
                p-3
                card
                border-bottom-0 border-top-0
                rounded-20
                border-start-0
                right-radius
              "
            >
              <div class="card-body">
                <div class="d-flex justify-content-start gap-3">
                  <img
                    :src="tokenDatas.image?.small"
                    width="40px"
                    height="40px"
                    alt=""
                  />
                  <div class="d-flex flex-column gap-1">
                    <h6 class="mb-0">
                      {{ tokenDatas.name }}
                    </h6>
                    <p>
                      {{ tokenDatas.symbol }}
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-start gap-2 flex-wrap">
                  <div
                    class="
                      btn
                      effect-click
                      btn-xs-size
                      bg-snow
                      text-dark
                      rounded-pill
                    "
                  >
                    Rank #{{ tokenDatas.market_cap_rank }}
                  </div>
                  <div
                    class="
                      btn
                      effect-click
                      btn-xs-size
                      bg-snow
                      text-dark
                      rounded-pill
                    "
                    v-for="(cate, cateIndex) in tokenDatas.categories"
                    :key="cateIndex"
                  >
                    {{ cate }}
                  </div>
                </div>

                <div class="mt-4">
                  <div class="text-secondary">INFORMATION</div>
                  <div
                    class="d-flex justify-content-start flex-wrap gap-2 mt-2"
                  >
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Website
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li
                          v-for="(
                            homepage, homepageIndex
                          ) in tokenDatas.links?.homepage.filter(
                            (x) => x !== ''
                          )"
                          :key="homepageIndex"
                        >
                          <a class="dropdown-item" href="#">{{ homepage }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Community
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li
                          v-if="
                            tokenDatas.links?.telegram_channel_identifier !== ''
                          "
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="
                              'https://t.me/' +
                              tokenDatas.links?.telegram_channel_identifier
                            "
                            target="_blank"
                            ><em class="ri ri-telegram-line h6 mb-0"></em>
                            Telegram</a
                          >
                        </li>
                        <li v-if="tokenDatas.links?.twitter_screen_name !== ''">
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="
                              'https://twitter.com/' +
                              tokenDatas.links?.twitter_screen_name
                            "
                            target="_blank"
                            ><em class="ri ri-twitter-line h6 mb-0"></em>
                            Twitter</a
                          >
                        </li>
                        <li v-if="tokenDatas.links?.facebook_username !== ''">
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="
                              'https://facebook.com/' +
                              tokenDatas.links?.facebook_username
                            "
                            target="_blank"
                            ><em class="ri ri-facebook-line h6 mb-0"></em>
                            Facebook</a
                          >
                        </li>
                        <li v-if="tokenDatas.links?.subreddit_url !== ''">
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="tokenDatas.links?.subreddit_url"
                            target="_blank"
                            ><em class="ri ri-reddit-line h6 mb-0"></em>
                            Reddit</a
                          >
                        </li>
                        <li
                          v-if="
                            tokenDatas.links?.bitcointalk_thread_identifier !==
                            null
                          "
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="
                              'https://bitcointalk.org/index.php?topic=' +
                              tokenDatas.links?.bitcointalk_thread_identifier
                            "
                            target="_blank"
                            ><em class="ri ri-bit-coin-line h6 mb-0"></em>
                            Bitcointalk</a
                          >
                        </li>
                        <li
                          v-for="(
                            chat, chatIndex
                          ) in tokenDatas.links?.chat_url.filter(
                            (x) => x !== ''
                          )"
                          :key="chatIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                            "
                            :href="chat"
                            ><em class="ri ri-discord-line h6 mb-0"></em>
                            {{ chat }}</a
                          >
                        </li>
                        <li
                          v-for="(
                            linkHomepage, linkHomepageIndex
                          ) in tokenDatas.links?.homepage.filter(
                            (x) => x !== ''
                          )"
                          :key="linkHomepageIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                            "
                            :href="linkHomepage"
                            ><em class="ri ri-global-line h6 mb-0"></em>
                            {{ linkHomepage }}</a
                          >
                        </li>
                        <li
                          v-for="(
                            officialForum, officialForumIndex
                          ) in tokenDatas.links?.official_forum_url.filter(
                            (x) => x !== ''
                          )"
                          :key="officialForumIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                            "
                            :href="officialForum"
                            ><em class="ri ri-message-2-line h6 mb-0"></em>
                            {{ officialForum }}</a
                          >
                        </li>
                        <li
                          v-for="(
                            announcement, announcementIndex
                          ) in tokenDatas.links?.announcement_url.filter(
                            (x) => x !== ''
                          )"
                          :key="announcementIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                            "
                            :href="announcement"
                            ><em class="ri ri-volume-up-line h6 mb-0"></em>
                            {{ announcement }}</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Explorer
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li
                          v-for="(
                            explorer, explorerIndex
                          ) in tokenDatas.links?.blockchain_site.filter(
                            (x) => x !== ''
                          )"
                          :key="explorerIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="explorer"
                            ><em class="ri-global-line h6 mb-0"></em>
                            {{ explorer }}</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Source Code
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li
                          v-for="(
                            github, githubIndex
                          ) in tokenDatas.links?.repos_url?.github.filter(
                            (x) => x !== ''
                          )"
                          :key="githubIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="github"
                            ><em class="ri-global-line h6 mb-0"></em>
                            {{ github }}</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Contracts
                      </button>
                      <ul
                        class="dropdown-menu scrollable-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li
                          v-for="(
                            values, names, contractIndex
                          ) in tokenDatas.platforms"
                          :key="contractIndex"
                        >
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            href=""
                            ><img src="" alt="" />
                            <div>
                              <h6>{{ names }}</h6>
                              <div>{{ values }}</div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button
                        class="
                          btn btn-xs-size btn-outline-secondary
                          rounded-pill
                          dropdown-toggle
                        "
                        type="button"
                        id="websiteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        More
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="websiteDropdown"
                      >
                        <li>
                          <a
                            class="
                              dropdown-item
                              align-items-center
                              d-flex
                              justify-content-start
                              gap-1
                              text-capitalize
                            "
                            :href="
                              'https://twitter.com/search?q=' +
                              '$' +
                              tokenDatas.symbol
                            "
                            ><em class="ri-file-search-line h6 mb-0"></em>
                            Search on Twitter</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                col-7
                p-3
                card
                border-bottom-0 border-top-0
                rounded-20
                border-start-0 border-end-0
                left-radius
              "
            >
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column w-100">
                    <div class="text-uppercase text-sm">
                      {{ tokenDatas.symbol }} Price
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-2
                        mt-2
                      "
                    >
                      <h2
                        class="text-dark mb-0"
                        v-if="
                          tokenDatas.market_data?.current_price?.usd > 0.001
                        "
                      >
                        ${{
                          Number(
                            tokenDatas.market_data?.current_price?.usd
                          ).toFixed(2)
                        }}
                      </h2>
                      <h2 class="text-dark mb-0" v-else>
                        ${{
                          Number(
                            tokenDatas.market_data?.current_price?.usd
                          ).toFixed(8)
                        }}
                      </h2>
                      <span
                        class="
                          btn btn-xs-size
                          rounded-pill
                          border-success
                          bg-success bg-opacity-10
                          text-success
                        "
                        >{{
                          tokenDatas.market_data?.price_change_percentage_24h
                        }}</span
                      >
                    </div>
                    <div class="w-100 mt-3">
                      <div class="progress">
                        <div
                          class="progress-bar bg-success"
                          role="progressbar"
                          :style="'width: ' + getBars() + '%'"
                          :aria-valuenow="
                            tokenDatas.market_data?.current_price?.usd
                          "
                          :aria-valuemin="tokenDatas.market_data?.low_24h?.usd"
                          :aria-valuemax="tokenDatas.market_data?.high_24h?.usd"
                        >
                          {{ tokenDatas.market_data?.current_price?.usd }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between w-100 mt-2">
                        <div>${{ tokenDatas.market_data?.low_24h?.usd }}</div>
                        <div>${{ tokenDatas.market_data?.high_24h?.usd }}</div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="card rounded-10">
                          <div class="card-body p-3">
                            <div class="d-flex justify-content-between">
                              <div>
                                <div class="text-xs fw-bold">Market Cap</div>
                                <div
                                  class="text-xs"
                                  v-if="tokenDatas?.market_data?.usd === {}"
                                >
                                  No Data
                                </div>
                                <div class="text-xs" v-else>
                                  {{
                                    Number(
                                      tokenDatas.market_data?.market_cap?.usd
                                    ) | toUSD
                                  }}
                                </div>
                              </div>
                              <div class="text-success fw-bold">
                                {{
                                  Number(
                                    tokenDatas.market_data
                                      ?.market_cap_change_percentage_24h
                                  ) | toTwoDigits
                                }}%
                              </div>
                            </div>

                            <div
                              class="d-flex justify-content-between gap-3 mt-3"
                            >
                              <div>
                                <div class="text-xs fw-bold">TVL Locked</div>
                                <div class="text-xs">
                                  {{
                                    tokenDatas.market_data?.total_value_locked
                                      ?.usd || "-" | toUSD
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="card rounded-10">
                          <div class="card-body p-3">
                            <div class="d-flex justify-content-between gap-3">
                              <div>
                                <div class="text-xs fw-bold">
                                  Diluted Valuation
                                </div>
                                <div
                                  class="text-xs"
                                  v-if="
                                    tokenDatas.market_data
                                      ?.fully_diluted_valuation.length > 0
                                  "
                                >
                                  {{
                                    Number(
                                      tokenDatas.market_data
                                        ?.fully_diluted_valuation
                                    ) | toUSD
                                  }}
                                </div>
                                <div class="text-xs" v-else>-</div>
                              </div>
                            </div>

                            <div
                              class="d-flex justify-content-between gap-3 mt-3"
                            >
                              <div>
                                <div class="text-xs fw-bold">
                                  Circulating Supply
                                </div>
                                <div
                                  class="text-xs"
                                  v-if="
                                    tokenDatas.market_data
                                      ?.circulating_supply !== null
                                  "
                                >
                                  {{
                                    Number(
                                      tokenDatas.market_data?.circulating_supply
                                    ) | toUSD
                                  }}
                                </div>
                                <div class="text-xs" v-else>
                                  {{
                                    tokenDatas.market_data
                                      ?.circulating_supply || "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="card rounded-10">
                          <div class="card-body p-3">
                            <div class="d-flex justify-content-between gap-3">
                              <div>
                                <div class="text-xs fw-bold">Total Supply</div>
                                <div
                                  class="text-xs"
                                  v-if="
                                    tokenDatas.market_data?.total_supply !==
                                    null
                                  "
                                >
                                  {{
                                    Number(tokenDatas.market_data?.total_supply)
                                      | toUSD
                                  }}
                                </div>
                                <div class="text-xs" v-else>-</div>
                              </div>
                            </div>

                            <div
                              class="d-flex justify-content-between gap-3 mt-3"
                            >
                              <div>
                                <div class="text-xs fw-bold">Max Supply</div>
                                <div
                                  class="text-xs"
                                  v-if="
                                    tokenDatas.market_data?.max_supply !== null
                                  "
                                >
                                  {{
                                    Number(tokenDatas.market_data?.max_supply)
                                      | toUSD
                                  }}
                                </div>
                                <div class="text-xs" v-else>
                                  {{
                                    tokenDatas.market_data?.max_supply || "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white py-3 un-creator-ptofile rounded-20 mt-4">
            <div class="tab-creatore-profile">
              <ul
                class="nav nav-pills nav-pilled-tab w-100 mb-0"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="Overview-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Overview"
                    type="button"
                    role="tab"
                    aria-controls="Overview"
                    aria-selected="true"
                  >
                    Overview
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="Markets-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Markets"
                    type="button"
                    role="tab"
                    aria-controls="Markets"
                    aria-selected="false"
                  >
                    Markets
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="Converter-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Converter"
                    type="button"
                    role="tab"
                    aria-controls="Converter"
                    aria-selected="false"
                  >
                    Converter
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="Historical-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Historical"
                    type="button"
                    role="tab"
                    aria-controls="Historical"
                    aria-selected="false"
                  >
                    Historical Data
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content">
            <div
              class="tab-pane active"
              id="Overview"
              role="tabpanel"
              aria-labelledby="Overview-tab"
            >
              <div class="p-3 rounded-10 mt-4 bg-white">
                <ul
                  class="nav nav-pills nav-pilled-tab"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="General-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#General"
                      type="button"
                      role="tab"
                      aria-controls="General"
                      aria-selected="true"
                    >
                      General
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="Social-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Social"
                      type="button"
                      role="tab"
                      aria-controls="Social"
                      aria-selected="false"
                    >
                      Social
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="Security-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Security"
                      type="button"
                      role="tab"
                      aria-controls="Security"
                      aria-selected="false"
                    >
                      Security
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="Developer-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Developer"
                      type="button"
                      role="tab"
                      aria-controls="Developer"
                      aria-selected="false"
                    >
                      Developer
                    </button>
                  </li>
                </ul>
              </div>
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="General"
                  role="tabpanel"
                  aria-labelledby="General-tab"
                >
                  <div class="row mt-4">
                    <div class="col-8">
                      <div class="p-3 card border-0 rounded-20">
                        <div class="card-body">
                          <ul
                            class="nav nav-pills nav-pilled-tab"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <button
                                class="nav-link active"
                                id="Price-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Price"
                                type="button"
                                role="tab"
                                aria-controls="Price"
                                aria-selected="true"
                              >
                                Price
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button
                                class="nav-link"
                                id="Marketcap-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Marketcap"
                                type="button"
                                role="tab"
                                aria-controls="Marketcap"
                                aria-selected="false"
                              >
                                Marketcap
                              </button>
                            </li>
                          </ul>
                          <h5 class="fw-bold mt-3">
                            {{ tokenDatas.name }} Price Chart
                            <span class="text-uppercase"
                              >({{ tokenDatas.symbol }}/USD)</span
                            >
                          </h5>
                          <div class="tab-content">
                            <div
                              class="tab-pane active"
                              id="Price"
                              role="tabpanel"
                              aria-labelledby="Price-tab"
                            >
                              <div class="d-flex flex-column mt-4">
                                <div
                                  class="
                                    d-flex
                                    flex-row
                                    justify-content-between
                                    align-items-center
                                    gap-1
                                    mb-4
                                    w-100
                                  "
                                >
                                  <div
                                    class="
                                      d-flex
                                      justify-content-start
                                      gap-1
                                      align-items-center
                                    "
                                  >
                                    <button
                                      :class="someComputed(1)"
                                      class="btn btn-xs-size"
                                      @click="setData(1)"
                                    >
                                      24h
                                    </button>
                                    <button
                                      :class="someComputed(7)"
                                      class="btn btn-xs-size"
                                      @click="setData(7)"
                                    >
                                      7D
                                    </button>
                                    <button
                                      :class="someComputed(14)"
                                      class="btn btn-xs-size"
                                      @click="setData(14)"
                                    >
                                      14D
                                    </button>
                                    <button
                                      :class="someComputed(30)"
                                      class="btn btn-xs-size"
                                      @click="setData(30)"
                                    >
                                      30D
                                    </button>
                                    <button
                                      :class="someComputed(90)"
                                      class="btn btn-xs-size"
                                      @click="setData(90)"
                                    >
                                      90D
                                    </button>
                                    <button
                                      :class="someComputed(180)"
                                      class="btn btn-xs-size"
                                      @click="setData(180)"
                                    >
                                      180D
                                    </button>
                                    <button
                                      :class="someComputed(365)"
                                      class="btn btn-xs-size"
                                      @click="setData(365)"
                                    >
                                      365D
                                    </button>
                                    <button
                                      :class="someComputed('max')"
                                      class="btn btn-xs-size"
                                      @click="setData('max')"
                                    >
                                      Max
                                    </button>
                                  </div>
                                  <span class="text-end">
                                    Selected Date: {{ days }} Days
                                  </span>
                                </div>
                                <highcharts
                                  :constructor-type="'stockChart'"
                                  :options="chartOptionsPrices"
                                ></highcharts>
                              </div>
                            </div>

                            <div
                              class="tab-pane"
                              id="Marketcap"
                              role="tabpanel"
                              aria-labelledby="Marketcap-tab"
                            >
                              <div class="d-flex flex-column mt-4">
                                <div
                                  class="
                                    d-flex
                                    flex-row
                                    justify-content-between
                                    align-items-center
                                    gap-1
                                    mb-4
                                    w-100
                                  "
                                >
                                  <div
                                    class="
                                      d-flex
                                      justify-content-start
                                      gap-1
                                      align-items-center
                                    "
                                  >
                                    <button
                                      :class="someComputed(1)"
                                      class="btn btn-xs-size"
                                      @click="setData(1)"
                                    >
                                      24h
                                    </button>
                                    <button
                                      :class="someComputed(7)"
                                      class="btn btn-xs-size"
                                      @click="setData(7)"
                                    >
                                      7D
                                    </button>
                                    <button
                                      :class="someComputed(14)"
                                      class="btn btn-xs-size"
                                      @click="setData(14)"
                                    >
                                      14D
                                    </button>
                                    <button
                                      :class="someComputed(30)"
                                      class="btn btn-xs-size"
                                      @click="setData(30)"
                                    >
                                      30D
                                    </button>
                                    <button
                                      :class="someComputed(90)"
                                      class="btn btn-xs-size"
                                      @click="setData(90)"
                                    >
                                      90D
                                    </button>
                                    <button
                                      :class="someComputed(180)"
                                      class="btn btn-xs-size"
                                      @click="setData(180)"
                                    >
                                      180D
                                    </button>
                                    <button
                                      :class="someComputed(365)"
                                      class="btn btn-xs-size"
                                      @click="setData(365)"
                                    >
                                      365D
                                    </button>
                                    <button
                                      :class="someComputed('max')"
                                      class="btn btn-xs-size"
                                      @click="setData('max')"
                                    >
                                      Max
                                    </button>
                                  </div>
                                  <span class="text-end">
                                    Selected Date: {{ days }} Days
                                  </span>
                                </div>
                                <highcharts
                                  :constructor-type="'stockChart'"
                                  :options="chartOptionsMarketcap"
                                ></highcharts>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">1h</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_1h_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1h_in_currency
                                            ?.usd
                                        ).toFixed(2)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">24h</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_24h_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_24h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_24h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">7d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_7d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_7d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_7d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">14d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_14d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_14d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_14d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">30d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_30d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_30d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_30d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">1y</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_1y_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1y_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1y_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="p-2 card border-0 rounded-20">
                        <div class="card-body">
                          <div class="d-flex flex-column">
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <h6>
                                <span class="text-uppercase">{{
                                  tokenDatas.symbol
                                }}</span>
                                Price Statistic
                              </h6>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>{{ tokenDatas.name }} Price</div>
                              <div>
                                {{
                                  Number(
                                    tokenDatas.market_data?.current_price?.usd
                                  ) | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>24h Low / 24h High</div>
                              <div>
                                {{
                                  Number(tokenDatas.market_data?.low_24h?.usd)
                                    | toUSD
                                }}/{{
                                  Number(tokenDatas.market_data?.high_24h?.usd)
                                    | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>7d Low / 7d High</div>
                              <div></div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Trading Volume</div>
                              <div>
                                {{
                                  tokenDatas.market_data?.total_volume?.usd ||
                                  "-" | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Market Cap</div>
                              <div>
                                {{
                                  tokenDatas.market_data?.market_cap?.usd ||
                                  "-" | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Market Cap Dominance</div>
                              <div>
                                {{
                                  Number(
                                    (tokenDatas.market_data?.market_cap?.usd /
                                      totalMc) *
                                      100
                                  ).toFixed(3)
                                }}%
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Volume / Market Cap</div>
                              <div>
                                {{
                                  Number(
                                    tokenDatas.market_data?.total_volume?.usd /
                                      tokenDatas.market_data?.market_cap?.usd
                                  ).toFixed(4) || "-"
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>All Time High</div>
                              <div class="d-flex flex-column text-end">
                                <div class="d-flex justify-content-end gap-1">
                                  <div>
                                    {{
                                      tokenDatas.market_data?.ath?.usd ||
                                      "-" | toUSD
                                    }}
                                  </div>
                                  <div
                                    v-if="
                                      tokenDatas.market_data
                                        ?.ath_change_percentage > 0
                                    "
                                    class="text-success"
                                  >
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.ath_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                  <div v-else class="text-danger">
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.ath_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                </div>

                                <small class="text-end">{{
                                  moment(
                                    tokenDatas.market_data?.ath_date?.usd
                                  ).format("MMMM Do YYYY")
                                }}</small>
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>All Time Low</div>
                              <div class="d-flex flex-column text-end">
                                <div class="d-flex justify-content-end gap-1">
                                  <div>
                                    {{
                                      tokenDatas.market_data?.atl?.usd ||
                                      "-" | toUSD
                                    }}
                                  </div>
                                  <div
                                    v-if="
                                      tokenDatas.market_data
                                        ?.atl_change_percentage < 0
                                    "
                                    class="text-danger"
                                  >
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.atl_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                  <div v-else class="text-success">
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.atl_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                </div>

                                <small class="text-end">{{
                                  moment(
                                    tokenDatas.market_data?.atl_date?.usd
                                  ).format("MMMM Do YYYY")
                                }}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane"
                  id="Social"
                  role="tabpanel"
                  aria-labelledby="Social-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-4">
                      <h5>Social Statistic</h5>
                      <div class="row">
                        <div class="col-4">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-facebook-line"></em> Facebook
                                Likes
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data?.facebook_likes !=
                                  null
                                "
                              >
                                {{
                                  tokenDatas.community_data?.facebook_likes ||
                                  "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-twitter-line"></em> Twitter
                                Followers
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.twitter_followers != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.twitter_followers || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-telegram-line"></em> Telegram
                                Channel
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.telegram_channel_user_count != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.telegram_channel_user_count || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 class="mt-3">Reddit Statistic</h5>
                      <div class="row">
                        <div class="col-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Average Posts
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_average_posts_48 != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_average_posts_48 || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Average Comments
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_average_comments_48h != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_average_comments_48h || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Subscribers
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_subscribers != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_subscribers || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Account Active
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_accounts_active_48h != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_accounts_active_48h || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div
                  class="tab-pane"
                  id="Security"
                  role="tabpanel"
                  aria-labelledby="Security-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-4">On Progress</div>
                  </section>
                </div>

                <div
                  class="tab-pane"
                  id="Developer"
                  role="tabpanel"
                  aria-labelledby="Developer-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-4">
                      <h5>Developer Statistic</h5>
                      <div class="row">
                        <div
                          v-for="(devGithub, devGithubIndex) in tokenDatas.links
                            ?.repos_url?.github"
                          :key="devGithubIndex"
                        >
                          <GithubStats :url="devGithub" />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div
              class="tab-pane"
              id="Markets"
              role="tabpanel"
              aria-labelledby="Markets-tab"
            >
              <section class="bg-white rounded-20 mt-4">
                <div class="container p-4">
                  <div class="d-flex flex-wrap overflow-none">
                    <div class="table-responsive log-table-new">
                      <table class="table table-striped w-100">
                        <thead>
                          <tr class="align-items-center align-middle">
                            <td class="sortable-column fw-bold">#</td>
                            <td class="sortable-column fw-bold">Exchange</td>
                            <td class="sortable-column fw-bold">Pair</td>
                            <td class="sortable-column fw-bold">Price</td>
                            <td class="sortable-column fw-bold">Spread</td>
                            <td class="sortable-column fw-bold">+2% Depth</td>
                            <td class="sortable-column fw-bold">-2% Depth</td>
                            <td class="sortable-column fw-bold">24h Volume</td>
                            <td class="sortable-column fw-bold">Volume</td>
                            <td class="sortable-column fw-bold">Last Trade</td>
                            <td class="sortable-column fw-bold text-center">
                              Trust Score
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              marketData, indexMarket
                            ) in marketDatas.tickers"
                            :key="indexMarket"
                            class="align-items-center align-middle"
                          >
                            <td class="align-middle">{{ indexMarket + 1 }}</td>
                            <td class="align-middle">
                              <div
                                class="
                                  d-flex
                                  justify-content-start
                                  align-items-center
                                  gap-2
                                  fw-bold
                                  text-dark
                                "
                              >
                                <img
                                  :src="marketData.market?.logo"
                                  width="25"
                                  height="25"
                                  alt=""
                                />{{ marketData.market?.name }}
                              </div>
                            </td>
                            <td
                              class="align-middle"
                              v-if="marketData.base.startsWith('0X')"
                            >
                              DEX
                            </td>
                            <td class="align-middle" v-else>
                              <a
                                :href="marketData.trade_url"
                                class="text-decoration-none text-success"
                                >{{ marketData.base }}/{{
                                  marketData.target
                                }}</a
                              >
                            </td>
                            <td class="align-middle">
                              {{ marketData.converted_last?.usd }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.bid_ask_spread_percentage
                                  | toTwoDigits
                              }}%
                            </td>
                            <td class="align-middle">
                              {{ marketData.cost_to_move_up_usd | toUSDSimple }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.cost_to_move_down_usd | toUSDSimple
                              }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.converted_volume?.usd | toUSDSimple
                              }}
                            </td>
                            <td class="align-middle">
                              {{
                                (
                                  (marketData.converted_volume?.usd /
                                    tokenDatas.market_data?.total_volume?.usd) *
                                  100
                                ).toFixed(2)
                              }}%
                            </td>
                            <td class="align-middle">
                              {{
                                moment(marketData.last_traded_at).format(
                                  "MMMM Do YYYY"
                                )
                              }}
                            </td>
                            <td
                              class="align-middle text-center h1 text-success"
                              v-if="marketData.trust_score === 'green'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-danger"
                              v-else-if="marketData.trust_score === 'red'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-warning"
                              v-else-if="marketData.trust_score === 'yellow'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-dark"
                              v-else
                            >
                              •
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              class="tab-pane"
              id="Converter"
              role="tabpanel"
              aria-labelledby="Converter-tab"
            >
              <section class="bg-white rounded-20 mt-4">
                <div class="container p-4">
                  <div class="d-flex flex-column gap-2 overflow-none">
                    <h6>
                      1
                      <span class="text-uppercase">{{
                        tokenDatas.symbol
                      }}</span>
                      =
                      {{
                        Number(tokenDatas.market_data?.current_price.usd)
                          | toUSD
                      }}
                    </h6>
                    <div class="row">
                      <div class="col-6">
                        <div class="card bg-light rounded-10 border-0">
                          <div class="card-body">
                            <div>
                              <label class="form-label fw-bold">Uni</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >{{ tokenDatas.symbol }}</span
                                >
                                <input
                                  type="number"
                                  v-model="defNumber"
                                  @input="calculated($event.target.value)"
                                  class="form-control"
                                  placeholder="1"
                                />
                              </div>
                            </div>
                            <center>
                              <em
                                class="ri ri-exchange-line text-center h4"
                              ></em>
                            </center>
                            <div>
                              <label class="form-label fw-bold mt-2"
                                >Currency</label
                              >
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >USD</span
                                >
                                <input
                                  type="number"
                                  :value="resultPrice"
                                  :placeholder="
                                    tokenDatas.market_data?.current_price?.usd
                                  "
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card bg-light rounded-10 border-0">
                          <div class="card-body">
                            <div>
                              <label class="form-label fw-bold">Currency</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >USD</span
                                >
                                <input
                                  type="number"
                                  v-model="defNumberCurrency"
                                  class="form-control"
                                  @input="
                                    calculatedCurrency($event.target.value)
                                  "
                                />
                              </div>
                            </div>
                            <center>
                              <em
                                class="ri ri-exchange-line text-center h4"
                              ></em>
                            </center>
                            <div>
                              <label class="form-label fw-bold mt-2">Uni</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >{{ tokenDatas.symbol }}</span
                                >
                                <input
                                  type="number"
                                  :value="resultPriceCurrency"
                                  :placeholder="
                                    1 /
                                    tokenDatas.market_data?.current_price?.usd
                                  "
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              class="tab-pane"
              id="Historical"
              role="tabpanel"
              aria-labelledby="Historical-tab"
            >
              <section class="bg-white rounded-20 mt-4">
                <div class="container p-4">
                  <div class="d-flex flex-wrap overflow-none">
                    <div class="table-responsive log-table-new w-100">
                      <table class="table table-striped w-100">
                        <thead>
                          <tr class="align-items-center align-middle">
                            <td class="sortable-column fw-bold">Date</td>
                            <td class="sortable-column fw-bold">Market Cap</td>
                            <td class="sortable-column fw-bold">Volume</td>
                            <td class="sortable-column fw-bold">Open</td>
                            <td class="sortable-column fw-bold">Close</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="align-items-center align-middle"
                            v-for="(history, indexHistory) in sortedByDate"
                            :key="indexHistory"
                          >
                            <td class="align-middle">
                              {{
                                moment(history[0]).format("MMMM Do YYYY, HH:mm")
                              }}
                            </td>
                            <td class="align-middle"></td>
                            <td class="align-middle"></td>
                            <td class="align-middle">{{ history[1] }}</td>
                            <td class="align-middle">{{ history[4] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="bg-white rounded-20 mt-4" v-if="tokenDatas.length > 0">
            <div class="container p-4">
              <h5>
                <span>{{ tokenDatas.name }}</span> Price & Market Data
              </h5>
              <p class="font-monospace lh-lg">
                {{ tokenDatas.name }} price today is
                {{ Number(tokenDatas.market_data?.current_price.usd) | toUSD }}
                with a 24-hour trading volume of
                {{ Number(tokenDatas.market_data?.total_volume.usd) | toUSD }}.
                <span class="text-uppercase">{{ tokenDatas.symbol }}</span>
                price is down
                {{
                  tokenDatas.market_data
                    ?.price_change_percentage_24h_in_currency?.usd
                }}% in the last 24 hours. It has a circulating supply of
                {{ tokenDatas.market_data?.total_supply }}
                <span class="text-uppercase">{{
                  tokenDatas?.symbol || "-"
                }}</span>
                coins and a total supply of
                {{ tokenDatas.market_data?.max_supply }}. If you are looking to
                buy or sell {{ tokenDatas.name }},
                {{ tokenDatas.tickers[0]?.market?.name }} is currently the most
                active exchange. <br /><span
                  v-html="tokenDatas.description?.en"
                ></span>
              </p>
              <h5>
                What was the highest price for
                <span class="text-capitalize">{{ tokenDatas.name }}</span
                >?
              </h5>
              <p class="font-monospace lh-lg">
                {{ tokenDatas.name }} hit an all time high of
                {{ tokenDatas.market_data?.ath.usd | toUSD }} on
                {{
                  moment(tokenDatas.market_data?.ath_date?.usd).format(
                    "MMMM Do YYYY"
                  )
                }}
              </p>
              <h5>
                What was the highest lowest for
                <span class="text-capitalize">{{ tokenDatas.name }}</span
                >?
              </h5>
              <p class="font-monospace lh-lg">
                {{ tokenDatas.name }} hit an all time low of
                {{ tokenDatas.market_data?.atl.usd | toUSD }} on
                {{
                  moment(tokenDatas.market_data?.atl_date?.usd).format(
                    "MMMM Do YYYY"
                  )
                }}
              </p>
              <h5>
                What was the 24 hour trading volume of {{ tokenDatas.name }}?
              </h5>
              <p class="font-monospace lh-lg">
                The 24 hour trading volume of {{ tokenDatas.name }} is
                {{ tokenDatas.market_data?.total_volume.usd | toUSD }}.
              </p>
              <h5>Where can {{ tokenDatas.name }} be traded?</h5>
              <p class="font-monospace lh-lg">
                You can trade {{ tokenDatas.name }} on
                {{ tokenDatas.tickers[0].market?.name }},{{
                  tokenDatas.tickers[1].market?.name
                }}, and {{ tokenDatas.tickers[3].market?.name }}. Popular
                trading pairs for Bitcoin in the market includes
                {{ tokenDatas.tickers[0].base }}/{{
                  tokenDatas.tickers[0].target
                }},{{ tokenDatas.tickers[1].base }}/{{
                  tokenDatas.tickers[1].target
                }},{{ tokenDatas.tickers[2].base }}/{{
                  tokenDatas.tickers[2].target
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="d-lg-none d-block px-1">
      <div class="card margin-t-10 border-0">
        <div class="card-body">
          <div class="d-flex justify-content-start gap-2">
            <img
              :src="tokenDatas.image?.large"
              width="40px"
              height="40px"
              alt=""
            />
            <div
              class="
                d-flex
                justify-content-between
                w-100
                align-items-center
                gap-2
              "
            >
              <div class="d-flex flex-column gap-1">
                <h6 class="mb-0 w-100">
                  {{ tokenDatas.name }}
                  <small class="mb-0 text-uppercase text-secondary">{{
                    tokenDatas.symbol
                  }}</small>
                </h6>
                <div
                  class="d-flex justify-content-start align-items-center gap-2"
                >
                  <div>
                    <p
                      class="text-dark mb-0"
                      v-if="tokenDatas.market_data?.current_price?.usd > 0.001"
                    >
                      ${{
                        Number(
                          tokenDatas.market_data?.current_price?.usd
                        ).toFixed(2)
                      }}
                    </p>
                    <p class="text-dark mb-0" v-else>
                      ${{
                        Number(
                          tokenDatas.market_data?.current_price?.usd
                        ).toFixed(8)
                      }}
                    </p>
                  </div>
                  <small
                    v-if="
                      tokenDatas.market_data?.price_change_percentage_24h > 0
                    "
                    class="text-success"
                  >
                    +{{ tokenDatas.market_data?.price_change_percentage_24h }}
                  </small>
                  <small v-else class="text-danger">
                    {{ tokenDatas.market_data?.price_change_percentage_24h }}
                  </small>
                </div>
              </div>

              <div
                class="
                  btn
                  effect-click
                  btn-xs-size
                  bg-snow
                  text-dark
                  rounded-pill
                "
              >
                Rank #{{ tokenDatas.market_cap_rank }}
              </div>
            </div>
          </div>
          <div class="w-100 mt-3">
            <div class="progress">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="'width: ' + getBars() + '%'"
                :aria-valuenow="tokenDatas.market_data?.current_price?.usd"
                :aria-valuemin="tokenDatas.market_data?.low_24h?.usd"
                :aria-valuemax="tokenDatas.market_data?.high_24h?.usd"
              >
                {{ tokenDatas.market_data?.current_price?.usd }}
              </div>
            </div>
            <div class="d-flex justify-content-between w-100 mt-2">
              <div>${{ tokenDatas.market_data?.low_24h?.usd }}</div>
              <div>${{ tokenDatas.market_data?.high_24h?.usd }}</div>
            </div>
          </div>

          <div
            class="
              d-flex
              justify-content-start
              gap-2
              flex-nowrap
              mt-3
              w-100
              custom-scroll
            "
          >
            <div
              class="
                bg-snow
                py-1
                px-3
                w-100
                flex-shrink-1
                text-dark text-nowrap
                rounded-pill
              "
              v-for="(cate, cateIndex) in tokenDatas.categories"
              :key="cateIndex"
            >
              <small>
                {{ cate }}
              </small>
            </div>
          </div>

          <div class="mt-4 w-100 col-12">
            <div class="text-secondary">INFORMATION</div>
            <div class="d-flex gap-2 flex-wrap">
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Website
                </button>
                <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
                  <li
                    v-for="(
                      homepage, homepageIndex
                    ) in tokenDatas.links?.homepage.filter((x) => x !== '')"
                    :key="homepageIndex"
                  >
                    <a class="dropdown-item" href="#">{{ homepage }}</a>
                  </li>
                </ul>
              </div>
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Community
                </button>
                <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
                  <li
                    v-if="tokenDatas.links?.telegram_channel_identifier !== ''"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="
                        'https://t.me/' +
                        tokenDatas.links?.telegram_channel_identifier
                      "
                      target="_blank"
                      ><em class="ri ri-telegram-line h6 mb-0"></em> Telegram</a
                    >
                  </li>
                  <li v-if="tokenDatas.links?.twitter_screen_name !== ''">
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="
                        'https://twitter.com/' +
                        tokenDatas.links?.twitter_screen_name
                      "
                      target="_blank"
                      ><em class="ri ri-twitter-line h6 mb-0"></em> Twitter</a
                    >
                  </li>
                  <li v-if="tokenDatas.links?.facebook_username !== ''">
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="
                        'https://facebook.com/' +
                        tokenDatas.links?.facebook_username
                      "
                      target="_blank"
                      ><em class="ri ri-facebook-line h6 mb-0"></em> Facebook</a
                    >
                  </li>
                  <li v-if="tokenDatas.links?.subreddit_url !== ''">
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="tokenDatas.links?.subreddit_url"
                      target="_blank"
                      ><em class="ri ri-reddit-line h6 mb-0"></em> Reddit</a
                    >
                  </li>
                  <li
                    v-if="
                      tokenDatas.links?.bitcointalk_thread_identifier !== null
                    "
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="
                        'https://bitcointalk.org/index.php?topic=' +
                        tokenDatas.links?.bitcointalk_thread_identifier
                      "
                      target="_blank"
                      ><em class="ri ri-bit-coin-line h6 mb-0"></em>
                      Bitcointalk</a
                    >
                  </li>
                  <li
                    v-for="(
                      chat, chatIndex
                    ) in tokenDatas.links?.chat_url.filter((x) => x !== '')"
                    :key="chatIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                      "
                      :href="chat"
                      ><em class="ri ri-discord-line h6 mb-0"></em>
                      {{ chat }}</a
                    >
                  </li>
                  <li
                    v-for="(
                      linkHomepage, linkHomepageIndex
                    ) in tokenDatas.links?.homepage.filter((x) => x !== '')"
                    :key="linkHomepageIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                      "
                      :href="linkHomepage"
                      ><em class="ri ri-global-line h6 mb-0"></em>
                      {{ linkHomepage }}</a
                    >
                  </li>
                  <li
                    v-for="(
                      officialForum, officialForumIndex
                    ) in tokenDatas.links?.official_forum_url.filter(
                      (x) => x !== ''
                    )"
                    :key="officialForumIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                      "
                      :href="officialForum"
                      ><em class="ri ri-message-2-line h6 mb-0"></em>
                      {{ officialForum }}</a
                    >
                  </li>
                  <li
                    v-for="(
                      announcement, announcementIndex
                    ) in tokenDatas.links?.announcement_url.filter(
                      (x) => x !== ''
                    )"
                    :key="announcementIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                      "
                      :href="announcement"
                      ><em class="ri ri-volume-up-line h6 mb-0"></em>
                      {{ announcement }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Explorer
                </button>
                <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
                  <li
                    v-for="(
                      explorer, explorerIndex
                    ) in tokenDatas.links?.blockchain_site.filter(
                      (x) => x !== ''
                    )"
                    :key="explorerIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="explorer"
                      ><em class="ri-global-line h6 mb-0"></em>
                      {{ explorer }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Source Code
                </button>
                <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
                  <li
                    v-for="(
                      github, githubIndex
                    ) in tokenDatas.links?.repos_url?.github.filter(
                      (x) => x !== ''
                    )"
                    :key="githubIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="github"
                      ><em class="ri-global-line h6 mb-0"></em> {{ github }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Contracts
                </button>
                <ul
                  class="dropdown-menu scrollable-menu"
                  aria-labelledby="websiteDropdown"
                >
                  <li
                    v-for="(
                      values, names, contractIndex
                    ) in tokenDatas.platforms"
                    :key="contractIndex"
                  >
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      href=""
                      ><img src="" alt="" />
                      <div>
                        <h6>{{ names }}</h6>
                        <div>{{ values }}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="dropdown flex-shrink-1">
                <button
                  class="
                    btn btn-xs-size btn-outline-secondary
                    rounded-pill
                    dropdown-toggle
                  "
                  type="button"
                  id="websiteDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  More
                </button>
                <ul class="dropdown-menu" aria-labelledby="websiteDropdown">
                  <li>
                    <a
                      class="
                        dropdown-item
                        align-items-center
                        d-flex
                        justify-content-start
                        gap-1
                        text-capitalize
                      "
                      :href="
                        'https://twitter.com/search?q=' +
                        '$' +
                        tokenDatas.symbol
                      "
                      ><em class="ri-file-search-line h6 mb-0"></em> Search on
                      Twitter</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-start gap-3 mt-3 custom-scroll">
            <div class="col-10">
              <div class="card rounded-10">
                <div class="card-body p-3">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="text-xs fw-bold">Market Cap</div>
                      <div
                        class="text-xs"
                        v-if="tokenDatas?.market_data?.usd === {}"
                      >
                        No Data
                      </div>
                      <div class="text-xs" v-else>
                        {{
                          Number(tokenDatas.market_data?.market_cap?.usd)
                            | toUSD
                        }}
                      </div>
                    </div>
                    <div class="text-success fw-bold">
                      {{
                        Number(
                          tokenDatas.market_data
                            ?.market_cap_change_percentage_24h
                        ) | toTwoDigits
                      }}%
                    </div>
                  </div>

                  <div class="d-flex justify-content-between gap-3 mt-3">
                    <div>
                      <div class="text-xs fw-bold">TVL Locked</div>
                      <div class="text-xs">
                        {{
                          tokenDatas.market_data?.total_value_locked?.usd ||
                          "-" | toUSD
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-10">
              <div class="card rounded-10">
                <div class="card-body p-3">
                  <div class="d-flex justify-content-between gap-3">
                    <div>
                      <div class="text-xs fw-bold">Diluted Valuation</div>
                      <div
                        class="text-xs"
                        v-if="
                          tokenDatas.market_data?.fully_diluted_valuation
                            .length > 0
                        "
                      >
                        {{
                          Number(
                            tokenDatas.market_data?.fully_diluted_valuation
                          ) | toUSD
                        }}
                      </div>
                      <div class="text-xs" v-else>-</div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between gap-3 mt-3">
                    <div>
                      <div class="text-xs fw-bold">Circulating Supply</div>
                      <div
                        class="text-xs"
                        v-if="
                          tokenDatas.market_data?.circulating_supply !== null
                        "
                      >
                        {{
                          Number(tokenDatas.market_data?.circulating_supply)
                            | toUSD
                        }}
                      </div>
                      <div class="text-xs" v-else>
                        {{ tokenDatas.market_data?.circulating_supply || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-10">
              <div class="card rounded-10">
                <div class="card-body p-3">
                  <div class="d-flex justify-content-between gap-3">
                    <div>
                      <div class="text-xs fw-bold">Total Supply</div>
                      <div
                        class="text-xs"
                        v-if="tokenDatas.market_data?.total_supply !== null"
                      >
                        {{
                          Number(tokenDatas.market_data?.total_supply) | toUSD
                        }}
                      </div>
                      <div class="text-xs" v-else>-</div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between gap-3 mt-3">
                    <div>
                      <div class="text-xs fw-bold">Max Supply</div>
                      <div
                        class="text-xs"
                        v-if="tokenDatas.market_data?.max_supply !== null"
                      >
                        {{ Number(tokenDatas.market_data?.max_supply) | toUSD }}
                      </div>
                      <div class="text-xs" v-else>
                        {{ tokenDatas.market_data?.max_supply || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white py-3 un-creator-ptofile rounded-20 mt-4">
            <div class="px-0 tab-creatore-profile">
              <ul
                class="nav nav-pills nav-pilled-tab w-100 mb-0 custom-scroll"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-Overview-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Overview"
                    type="button"
                    role="tab"
                    aria-controls="pills-Overview"
                    aria-selected="true"
                  >
                    Overview
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-Markets-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Markets"
                    type="button"
                    role="tab"
                    aria-controls="pills-Markets"
                    aria-selected="false"
                  >
                    Markets
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-Converter-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Converter"
                    type="button"
                    role="tab"
                    aria-controls="pills-Converter"
                    aria-selected="false"
                  >
                    Converter
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-nowrap"
                    id="pills-Historical-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Historical"
                    type="button"
                    role="tab"
                    aria-controls="pills-Historical"
                    aria-selected="false"
                  >
                    Historical Data
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content content-custome-data" id="pills-tabContent">
            <div
              class="tab-pane active"
              id="pills-Overview"
              role="tabpanel"
              aria-labelledby="pills-Overview-tab"
            >
              <div class="p-0 mt-4 bg-white main-search-header style-border">
                <ul
                  class="nav nav-pills nav-tab-search flex-nowrap custom-scroll"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-General-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-General"
                      type="button"
                      role="tab"
                      aria-controls="pills-General"
                      aria-selected="true"
                    >
                      General
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Social-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Social"
                      type="button"
                      role="tab"
                      aria-controls="pills-Social"
                      aria-selected="false"
                    >
                      Social
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Security-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Security"
                      type="button"
                      role="tab"
                      aria-controls="pills-Security"
                      aria-selected="false"
                    >
                      Security
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Developer-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Developer"
                      type="button"
                      role="tab"
                      aria-controls="pills-Developer"
                      aria-selected="false"
                    >
                      Developer
                    </button>
                  </li>
                </ul>
              </div>
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="pills-General"
                  role="tabpanel"
                  aria-labelledby="pills-General-tab"
                >
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="card px-0 border-0 rounded-20">
                        <div class="card-body">
                          <ul
                            class="nav nav-pills nav-pilled-tab"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li class="nav-item" role="presentation">
                              <button
                                class="nav-link active"
                                id="pills-Price-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-Price"
                                type="button"
                                role="tab"
                                aria-controls="pills-Price"
                                aria-selected="true"
                              >
                                Price
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button
                                class="nav-link"
                                id="pills-Marketcap-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-Marketcap"
                                type="button"
                                role="tab"
                                aria-controls="pills-Marketcap"
                                aria-selected="false"
                              >
                                Marketcap
                              </button>
                            </li>
                          </ul>
                          <h5 class="fw-bold mt-3">
                            {{ tokenDatas.name }} Price Chart
                            <span class="text-uppercase"
                              >({{ tokenDatas.symbol }}/USD)</span
                            >
                          </h5>
                          <div class="tab-content">
                            <div
                              class="tab-pane active"
                              id="pills-Price"
                              role="tabpanel"
                              aria-labelledby="pills-Price-tab"
                            >
                              <div class="d-flex flex-column mt-4">
                                <div class="row w-100">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-start
                                      gap-1
                                      align-items-center
                                      custom-scroll
                                    "
                                  >
                                    <button
                                      :class="someComputed(1)"
                                      class="btn btn-xs-size"
                                      @click="setData(1)"
                                    >
                                      24h
                                    </button>
                                    <button
                                      :class="someComputed(7)"
                                      class="btn btn-xs-size"
                                      @click="setData(7)"
                                    >
                                      7D
                                    </button>
                                    <button
                                      :class="someComputed(14)"
                                      class="btn btn-xs-size"
                                      @click="setData(14)"
                                    >
                                      14D
                                    </button>
                                    <button
                                      :class="someComputed(30)"
                                      class="btn btn-xs-size"
                                      @click="setData(30)"
                                    >
                                      30D
                                    </button>
                                    <button
                                      :class="someComputed(90)"
                                      class="btn btn-xs-size"
                                      @click="setData(90)"
                                    >
                                      90D
                                    </button>
                                    <button
                                      :class="someComputed(180)"
                                      class="btn btn-xs-size"
                                      @click="setData(180)"
                                    >
                                      180D
                                    </button>
                                    <button
                                      :class="someComputed(365)"
                                      class="btn btn-xs-size"
                                      @click="setData(365)"
                                    >
                                      365D
                                    </button>
                                    <button
                                      :class="someComputed('max')"
                                      class="btn btn-xs-size"
                                      @click="setData('max')"
                                    >
                                      Max
                                    </button>
                                  </div>
                                  <span class="text-end mt-2">
                                    Selected Date: {{ days }} Days
                                  </span>
                                </div>
                                <highcharts
                                  :constructor-type="'stockChart'"
                                  :options="chartOptionsPrices"
                                ></highcharts>
                              </div>
                            </div>

                            <div
                              class="tab-pane"
                              id="pills-Marketcap"
                              role="tabpanel"
                              aria-labelledby="pills-Marketcap-tab"
                            >
                              <div class="d-flex flex-column mt-4">
                                <div class="row w-100 custom-scroll">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-start
                                      gap-1
                                      align-items-center
                                    "
                                  >
                                    <button
                                      :class="someComputed(1)"
                                      class="btn btn-xs-size"
                                      @click="setData(1)"
                                    >
                                      24h
                                    </button>
                                    <button
                                      :class="someComputed(7)"
                                      class="btn btn-xs-size"
                                      @click="setData(7)"
                                    >
                                      7D
                                    </button>
                                    <button
                                      :class="someComputed(14)"
                                      class="btn btn-xs-size"
                                      @click="setData(14)"
                                    >
                                      14D
                                    </button>
                                    <button
                                      :class="someComputed(30)"
                                      class="btn btn-xs-size"
                                      @click="setData(30)"
                                    >
                                      30D
                                    </button>
                                    <button
                                      :class="someComputed(90)"
                                      class="btn btn-xs-size"
                                      @click="setData(90)"
                                    >
                                      90D
                                    </button>
                                    <button
                                      :class="someComputed(180)"
                                      class="btn btn-xs-size"
                                      @click="setData(180)"
                                    >
                                      180D
                                    </button>
                                    <button
                                      :class="someComputed(365)"
                                      class="btn btn-xs-size"
                                      @click="setData(365)"
                                    >
                                      365D
                                    </button>
                                    <button
                                      :class="someComputed('max')"
                                      class="btn btn-xs-size"
                                      @click="setData('max')"
                                    >
                                      Max
                                    </button>
                                  </div>
                                  <span class="text-end mt-2">
                                    Selected Date: {{ days }} Days
                                  </span>
                                </div>
                                <highcharts
                                  :constructor-type="'stockChart'"
                                  :options="chartOptionsMarketcap"
                                ></highcharts>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-6">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">1h</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_1h_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1h_in_currency
                                            ?.usd
                                        ).toFixed(2)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">24h</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_24h_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_24h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_24h_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">7d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_7d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_7d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_7d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">14d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_14d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_14d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_14d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">30d</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_30d_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_30d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_30d_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-3">
                              <div class="card rounded-10">
                                <div class="card-body">
                                  <div
                                    class="
                                      d-flex
                                      justify-content-between
                                      align-items-center
                                    "
                                  >
                                    <div class="mb-0">1y</div>
                                    <p
                                      class="mb-0 text-center text-success"
                                      v-if="
                                        tokenDatas.market_data
                                          ?.price_change_percentage_1y_in_currency
                                          ?.usd > 0
                                      "
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1y_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}%
                                    </p>
                                    <p
                                      class="mb-0 text-center text-danger"
                                      v-else
                                    >
                                      {{
                                        Number(
                                          tokenDatas.market_data
                                            ?.price_change_percentage_1y_in_currency
                                            ?.usd
                                        ).toFixed(3)
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="p-0 card border-0 rounded-20">
                        <div class="card-body">
                          <div class="d-flex flex-column">
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <h6>
                                <span class="text-uppercase">{{
                                  tokenDatas.symbol
                                }}</span>
                                Price Statistic
                              </h6>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>{{ tokenDatas.name }} Price</div>
                              <div>
                                {{
                                  Number(
                                    tokenDatas.market_data?.current_price?.usd
                                  ) | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>24h Low / 24h High</div>
                              <div>
                                {{
                                  Number(tokenDatas.market_data?.low_24h?.usd)
                                    | toUSD
                                }}/{{
                                  Number(tokenDatas.market_data?.high_24h?.usd)
                                    | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>7d Low / 7d High</div>
                              <div></div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Trading Volume</div>
                              <div>
                                {{
                                  tokenDatas.market_data?.total_volume?.usd ||
                                  "-" | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Market Cap</div>
                              <div>
                                {{
                                  tokenDatas.market_data?.market_cap?.usd ||
                                  "-" | toUSD
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Market Cap Dominance</div>
                              <div>
                                {{
                                  Number(
                                    (tokenDatas.market_data?.market_cap?.usd /
                                      totalMc) *
                                      100
                                  ).toFixed(3)
                                }}%
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>Volume / Market Cap</div>
                              <div>
                                {{
                                  Number(
                                    tokenDatas.market_data?.total_volume?.usd /
                                      tokenDatas.market_data?.market_cap?.usd
                                  ).toFixed(4) || "-"
                                }}
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>All Time High</div>
                              <div class="d-flex flex-column text-end">
                                <div class="d-flex justify-content-end gap-1">
                                  <div>
                                    {{
                                      tokenDatas.market_data?.ath?.usd ||
                                      "-" | toUSD
                                    }}
                                  </div>
                                  <div
                                    v-if="
                                      tokenDatas.market_data
                                        ?.ath_change_percentage > 0
                                    "
                                    class="text-success"
                                  >
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.ath_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                  <div v-else class="text-danger">
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.ath_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                </div>

                                <small class="text-end">{{
                                  moment(
                                    tokenDatas.market_data?.ath_date?.usd
                                  ).format("MMMM Do YYYY")
                                }}</small>
                              </div>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-content-between
                                align-items-center
                                gap-1
                                mb-4
                                w-100
                              "
                            >
                              <div>All Time Low</div>
                              <div class="d-flex flex-column text-end">
                                <div class="d-flex justify-content-end gap-1">
                                  <div>
                                    {{
                                      tokenDatas.market_data?.atl?.usd ||
                                      "-" | toUSD
                                    }}
                                  </div>
                                  <div
                                    v-if="
                                      tokenDatas.market_data
                                        ?.atl_change_percentage < 0
                                    "
                                    class="text-danger"
                                  >
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.atl_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                  <div v-else class="text-success">
                                    {{
                                      Number(
                                        tokenDatas.market_data
                                          ?.atl_change_percentage?.usd
                                      ).toFixed(2)
                                    }}%
                                  </div>
                                </div>

                                <small class="text-end">{{
                                  moment(
                                    tokenDatas.market_data?.atl_date?.usd
                                  ).format("MMMM Do YYYY")
                                }}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane"
                  id="pills-Social"
                  role="tabpanel"
                  aria-labelledby="pills-Social-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-0">
                      <h5>Social Statistic</h5>
                      <div class="row">
                        <div class="col-12">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-facebook-line"></em> Facebook
                                Likes
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data?.facebook_likes !=
                                  null
                                "
                              >
                                {{
                                  tokenDatas.community_data?.facebook_likes ||
                                  "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-twitter-line"></em> Twitter
                                Followers
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.twitter_followers != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.twitter_followers || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-telegram-line"></em> Telegram
                                Channel
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.telegram_channel_user_count != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.telegram_channel_user_count || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 class="mt-3">Reddit Statistic</h5>
                      <div class="row">
                        <div class="col-12">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Average Posts
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_average_posts_48 != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_average_posts_48 || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Average Comments
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_average_comments_48h != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_average_comments_48h || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Subscribers
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_subscribers != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_subscribers || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="card rounded-10">
                            <div class="card-body">
                              <h6 class="d-flex justify-content-start gap-1">
                                <em class="ri ri-reddit-line"></em> Reddit
                                Account Active
                              </h6>
                              <h5
                                class="fw-bold"
                                v-if="
                                  tokenDatas.community_data
                                    ?.reddit_accounts_active_48h != null
                                "
                              >
                                {{
                                  tokenDatas.community_data
                                    ?.reddit_accounts_active_48h || "-"
                                }}
                              </h5>
                              <h5 class="fw-bold" v-else>-</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div
                  class="tab-pane"
                  id="pills-Security"
                  role="tabpanel"
                  aria-labelledby="pills-Security-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-0">On Progress</div>
                  </section>
                </div>

                <div
                  class="tab-pane"
                  id="pills-Developer"
                  role="tabpanel"
                  aria-labelledby="pills-Developer-tab"
                >
                  <section class="bg-white rounded-20 mt-4">
                    <div class="container p-0">
                      <h5>Developer Statistic</h5>
                      <div class="row">
                        <div
                          v-for="(devGithub, devGithubIndex) in tokenDatas.links
                            ?.repos_url?.github"
                          :key="devGithubIndex"
                        >
                          <GithubStats :url="devGithub" />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div
              class="tab-pane"
              id="pills-Markets"
              role="tabpanel"
              aria-labelledby="pills-Markets-tab"
            >
              <section class="bg-white rounded-20 mt-1">
                <div class="container p-0">
                  <div class="d-flex flex-wrap overflow-none">
                    <div class="table-responsive log-table-new">
                      <table class="table table-striped w-100">
                        <thead>
                          <tr class="align-items-center align-middle">
                            <td class="sortable-column fw-bold">#</td>
                            <td class="sortable-column fw-bold">Exchange</td>
                            <td class="sortable-column fw-bold">Pair</td>
                            <td class="sortable-column fw-bold">Price</td>
                            <td class="sortable-column fw-bold">Spread</td>
                            <td class="sortable-column fw-bold">+2% Depth</td>
                            <td class="sortable-column fw-bold">-2% Depth</td>
                            <td class="sortable-column fw-bold">24h Volume</td>
                            <td class="sortable-column fw-bold">Volume</td>
                            <td class="sortable-column fw-bold">Last Trade</td>
                            <td class="sortable-column fw-bold text-center">
                              Trust Score
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              marketData, indexMarket
                            ) in marketDatas.tickers"
                            :key="indexMarket"
                            class="align-items-center align-middle"
                          >
                            <td class="align-middle">{{ indexMarket + 1 }}</td>
                            <td class="align-middle">
                              <div
                                class="
                                  d-flex
                                  justify-content-start
                                  align-items-center
                                  gap-2
                                  fw-bold
                                  text-dark
                                "
                              >
                                <img
                                  :src="marketData.market?.logo"
                                  width="25"
                                  height="25"
                                  alt=""
                                />{{ marketData.market?.name }}
                              </div>
                            </td>
                            <td
                              class="align-middle"
                              v-if="marketData.base.startsWith('0X')"
                            >
                              DEX
                            </td>
                            <td class="align-middle" v-else>
                              <a
                                :href="marketData.trade_url"
                                class="text-decoration-none text-success"
                                >{{ marketData.base }}/{{
                                  marketData.target
                                }}</a
                              >
                            </td>
                            <td class="align-middle">
                              {{ marketData.converted_last?.usd }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.bid_ask_spread_percentage
                                  | toTwoDigits
                              }}%
                            </td>
                            <td class="align-middle">
                              {{ marketData.cost_to_move_up_usd | toUSDSimple }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.cost_to_move_down_usd | toUSDSimple
                              }}
                            </td>
                            <td class="align-middle">
                              {{
                                marketData.converted_volume?.usd | toUSDSimple
                              }}
                            </td>
                            <td class="align-middle">
                              {{
                                (
                                  (marketData.converted_volume?.usd /
                                    tokenDatas.market_data?.total_volume?.usd) *
                                  100
                                ).toFixed(2)
                              }}%
                            </td>
                            <td class="align-middle">
                              {{
                                moment(marketData.last_traded_at).format(
                                  "MMMM Do YYYY"
                                )
                              }}
                            </td>
                            <td
                              class="align-middle text-center h1 text-success"
                              v-if="marketData.trust_score === 'green'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-danger"
                              v-else-if="marketData.trust_score === 'red'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-warning"
                              v-else-if="marketData.trust_score === 'yellow'"
                            >
                              •
                            </td>
                            <td
                              class="align-middle text-center h1 text-dark"
                              v-else
                            >
                              •
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              class="tab-pane"
              id="pills-Converter"
              role="tabpanel"
              aria-labelledby="pills-Converter-tab"
            >
              <section class="bg-white rounded-20 mt-4">
                <div class="container p-0">
                  <div class="d-flex flex-column gap-2 overflow-none">
                    <h6>
                      1
                      <span class="text-uppercase">{{
                        tokenDatas.symbol
                      }}</span>
                      =
                      {{
                        Number(tokenDatas.market_data?.current_price.usd)
                          | toUSD
                      }}
                    </h6>
                    <div class="row">
                      <div class="col-12">
                        <div class="card bg-light rounded-10 border-0">
                          <div class="card-body">
                            <div>
                              <label class="form-label fw-bold">Uni</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >{{ tokenDatas.symbol }}</span
                                >
                                <input
                                  type="number"
                                  v-model="defNumber"
                                  @input="calculated($event.target.value)"
                                  class="form-control"
                                  placeholder="1"
                                />
                              </div>
                            </div>
                            <center>
                              <em
                                class="ri ri-exchange-line text-center h4"
                              ></em>
                            </center>
                            <div>
                              <label class="form-label fw-bold mt-2"
                                >Currency</label
                              >
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >USD</span
                                >
                                <input
                                  type="number"
                                  :value="resultPrice"
                                  :placeholder="
                                    tokenDatas.market_data?.current_price?.usd
                                  "
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mt-3">
                        <div class="card bg-light rounded-10 border-0">
                          <div class="card-body">
                            <div>
                              <label class="form-label fw-bold">Currency</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >USD</span
                                >
                                <input
                                  type="number"
                                  v-model="defNumberCurrency"
                                  class="form-control"
                                  @input="
                                    calculatedCurrency($event.target.value)
                                  "
                                />
                              </div>
                            </div>
                            <center>
                              <em
                                class="ri ri-exchange-line text-center h4"
                              ></em>
                            </center>
                            <div>
                              <label class="form-label fw-bold mt-2">Uni</label>
                              <div class="input-group mb-3">
                                <span
                                  class="
                                    input-group-text
                                    text-uppercase
                                    bg-white
                                  "
                                  id="basic-addon1"
                                  >{{ tokenDatas.symbol }}</span
                                >
                                <input
                                  type="number"
                                  :value="resultPriceCurrency"
                                  :placeholder="
                                    1 /
                                    tokenDatas.market_data?.current_price?.usd
                                  "
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              class="tab-pane"
              id="pills-Historical"
              role="tabpanel"
              aria-labelledby="pills-Historical-tab"
            >
              <section class="bg-white rounded-20 mt-4">
                <div class="container p-0">
                  <div class="d-flex flex-wrap overflow-none">
                    <div class="table-responsive log-table-new">
                      <table class="table table-striped w-100">
                        <thead>
                          <tr class="align-items-center align-middle">
                            <td class="sortable-column fw-bold">Date</td>
                            <td class="sortable-column fw-bold text-nowrap">
                              Market Cap
                            </td>
                            <td class="sortable-column fw-bold">Volume</td>
                            <td class="sortable-column fw-bold">Open</td>
                            <td class="sortable-column fw-bold">Close</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="align-items-center align-middle"
                            v-for="(history, indexHistory) in sortedByDate"
                            :key="indexHistory"
                          >
                            <td class="align-middle">
                              {{
                                moment(history[0]).format("MMMM Do YYYY, HH:mm")
                              }}
                            </td>
                            <td class="align-middle"></td>
                            <td class="align-middle"></td>
                            <td class="align-middle">{{ history[1] }}</td>
                            <td class="align-middle">{{ history[4] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import GithubStats from "../components/GithubStats.vue";
import axios from "axios";
import moment from "moment";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import darkTheme from "../themes/dark";
stockInit(Highcharts);
exportingInit(Highcharts);
Highcharts.setOptions(darkTheme);

export default {
  name: "TokenDetail",
  components: {
    Header,
    Footer,
    GithubStats,
    highcharts: Chart,
  },
  data() {
    return {
      tokenDatas: [],
      marketDatas: [],
      moment: moment,
      totalMc: 0,
      days: 1,
      ids: "",
      tokenIds: "",
      defNumber: 1,
      defNumberCurrency: 1,
      resultPrice: Number,
      resultPriceCurrency: Number,
      chartOptionsPrices: {
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            data: [],
          },
        ],
      },
      chartOptionsMarketcap: {
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            data: [],
          },
        ],
      },
      historicalData: [],
    };
  },
  mounted() {
    this.getTokenDetails();
    this.getMarketDatas();
    this.getHistoricalDatas();
    this.getChart();
    this.getTotalMC();
  },
  methods: {
    getTokenDetails() {
      this.tokenIds = this.$route.params.id;
      axios
        .get("https://api-data.dinodapps.com/api/v3/coins/" + this.tokenIds)
        .then((res) => {
          this.tokenDatas = res.data;
          // console.log(res.data)
        });

      this.$mixpanel.track("Visit Detail Token " + this.tokenIds);
    },
    getMarketDatas() {
      this.ids = this.$route.params.id;
      axios
        .get(
          "https://api.coingecko.com/api/v3/coins/" +
            this.ids +
            "/tickers?include_exchange_logo=true&page=1&depth=true"
        )
        .then((res) => {
          this.marketDatas = res.data;
        });
    },
    getBars() {
      let low = this.tokenDatas.market_data?.low_24h?.usd;
      let high = this.tokenDatas.market_data?.high_24h?.usd;
      let price = this.tokenDatas.market_data?.current_price?.usd;

      let widthProgress = ((price - low) / low / ((high - low) / low)) * 100;

      return widthProgress;
    },
    setData(value) {
      this.days = value;
      this.getChart();
    },
    someComputed: function (value) {
      return this.days == value
        ? "bg-dark text-white"
        : "btn-outline-dark text-dark";
    },
    getChart() {
      let idss = this.$route.params.id;
      axios
        .get(
          "https://api-data.dinodapps.com/api/v3/coins/" +
            idss +
            "/market_chart?vs_currency=usd&days=" +
            this.days
        )
        .then((res) => {
          this.chartOptionsPrices.series[0].data = res.data.prices.map(
            (coin) => {
              return coin;
            }
          );
          this.chartOptionsMarketcap.series[0].data = res.data.market_caps.map(
            (mc) => {
              return mc;
            }
          );
        });
    },
    getHistoricalDatas() {
      axios
        .get(
          "https://api.coingecko.com/api/v3/coins/uniswap/ohlc?vs_currency=usd&days=30"
        )
        .then((res) => {
          this.historicalData = res.data;
        });
    },
    calculated(value) {
      let priceDefault = this.tokenDatas?.market_data?.current_price?.usd;
      let calcPrice = priceDefault * value;
      this.resultPrice = calcPrice;
    },
    calculatedCurrency(value) {
      let priceDefault = this.tokenDatas?.market_data?.current_price?.usd;
      let calcPrice = value / priceDefault;
      this.resultPriceCurrency = calcPrice;
    },
    getTotalMC() {
      axios.get("https://api.coingecko.com/api/v3/global").then((res) => {
        this.totalMc = res.data.data.total_market_cap.usd;
      });
    },
  },
  computed: {
    sortedByDate() {
      return this.historicalData
        .slice()
        .sort((a, b) => new Date(b[0]) - new Date(a[0]));
    },
  },
};
</script>

<style scope>
.custom-scroll {
  overflow-x: auto;
}
.custom-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
/* Optional: show position indicator in red */
.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
