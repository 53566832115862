<template>
  <div class="card rounded-10 mt-3">
    <div class="card-body">
      <h6>
        {{ githubList.url }}
        <span class="badge bg-light text-dark"
          >Stars: {{ githubList.stars }}</span
        >
      </h6>
      <div class="row">
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Watcher:</span> {{ githubList.watchers }}
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Forks:</span> {{ githubList.forks }}
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Contributor:</span>
            {{ githubList.contributors }}
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Closed Issues:</span>
            {{ githubList.closedIssues }}
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Total Issues:</span>
            {{ githubList.totalIssues }}
          </p>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <p class="mb-0">
            <span class="fw-bold">Merged Pull Request:</span>
            {{ githubList.mergedPullRequests }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GithubStats",
  data() {
    return {
      githubList: {},
    };
  },
  props: {
    url: String,
  },
  mounted() {
    this.getDataGithub();
  },
  methods: {
    getDataGithub() {
      axios
        .get("https://api-social-data.dinodapps.com/github", {
          params: {
            github: this.$props.url,
          },
        })
        .then((res) => {
          this.githubList = res.data;
        });
    },
  },
};
</script>

<style>
</style>